<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>

          <v-alert color="green lighten-1" type="success" v-if="messages" dense>
            {{ messages }}
          </v-alert>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-card>
            <v-card-title>Recover Account</v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                @submit.prevent="resetPassword"
                lazy-validation
              >
                <v-text-field
                  v-model="requestForm.email"
                  :rules="[
                    (v) => !!v || '',
                    (v) => /.+@.+\..+/.test(v) || 'Invalid email address',
                  ]"
                  label="Email"
                  required
                ></v-text-field>

                <v-card-actions class="justify-center">
                  <v-btn
                    type="submit"
                    :disabled="!requestForm.email"
                    color="primary"
                    class="mr-4"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text></v-card
          ></v-row
        ></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";

export default {
  data: () => ({
    alerts: null,
    messages: null,
    requestForm: {
      email: "",
    },
  }),
  methods: {
    resetPassword() {
      requests
        .post(`/api/auth/password/recover`, this.requestForm)
        .then((res) => {
          this.alerts = null;
          this.messages = res.data.message;
        })
        .catch((err) => {
          this.messages = null;
          this.alerts = JSON.stringify(err.response.data.detail);
        });
    },
    test() {},
  },
};
</script>
